import { FoxBizRequest, FoxRestBizClient } from "@/api/client";

/**
 * 미담당민원 화면 관련 기능 클래스
 */
class ComplainManage {

    /**
     * 생성자
     */
    constructor() {
    }

    /**
     * 미담당민원 지정 정보 조회
     * @returns 
     */
    getComplainUserInfo() {
        return new Promise((resolve, reject) => {
            
            const url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url);
            const request = new FoxBizRequest();
            request.classId = 'Site.Admin.Biz.SystemBiz';
            request.methodId = 'GetComplainUserInfo';

            service.execute(request).then(response => {

                const rows = response.data.result.value.Table.rows;

                if (!!rows.length) resolve(rows);
                else resolve(null);
            }).catch(error => reject(error));
        });
    }

    /**
     * 미담당민원 지정 정보 수정
     * @param {Object} detail 
     * @returns 
     */
    saveComplainUserInfo(userInfo) {
        return new Promise((resolve, reject) => {
            
            const url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url);
            const request = new FoxBizRequest();
            request.classId = 'Site.Admin.Biz.SystemBiz'
            request.methodId = 'SaveComplainUserInfo'
            request.parameters = userInfo

            service.execute(request).then(response => {

                resolve(response.data)

            }).catch(error => reject(error));
        });
    }
}

export const complain = new ComplainManage();