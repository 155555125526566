<template>
    <div class="admin-content">
        <h3>미담당민원지정</h3>
        <div class="flex-wrap right">
            <div class="ml10">
                <div class="btn-wrap">
                    <button type="button" class="btn-box" @click="eventHandler.onUserSetClick">사용자지정</button>
                    <button type="button" class="btn-box gray" @click="methods.saveComplainUserInfo">저장</button>
                </div>
            </div>
        </div>

        <h4 class="mt30 mb10">그룹정보</h4>

        <div class="flex-wrap">
            <div>
                <div class="sch-wrap3">
                    <div>
                        <label>그룹ID</label>
                        <div>
                            <input type="text" value="MIN001" class="input-text" disabled>
                        </div>
                    </div>
                    <div>
                        <label>그룹명</label>
                        <div>
                            <input type="text" value="미담당민원관리자" class="input-text" disabled>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <h4 class="mt30 mb10">사용자정보</h4>

        <div class="flex-wrap">
            <div>
                <div class="sch-wrap3">
                    <div>
                        <label>사용자ID</label>
                        <div>
                            <input type="text" v-model="state.userInfo.EmplID" class="input-text" disabled>
                        </div>
                    </div>
                    <div>
                        <label>사용자명</label>
                        <div>
                            <input type="text" v-model="state.userInfo.EmplName" class="input-text" disabled>
                        </div>
                    </div>
                    <div>
                        <label>부서코드</label>
                        <div>
                            <input type="text" v-model="state.userInfo.DeptCode" class="input-text" disabled>
                        </div>
                    </div>
                    <div>
                        <label>부서명</label>
                        <div>
                            <input type="text" v-model="state.userInfo.Department" class="input-text" disabled>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 사용자 선택 다이얼로그 -->
        <Dialog
            :header="state.dialog.header"
            :visible="state.dialog.isOpen"
            :modal="true"
            @close="state.dialog.isOpen=false"
            @update:visible="state.dialog.isOpen = false">

            <div class="dialog-wrap1 col2">
                <h3 class="mb10">사용자 검색</h3>
                <div class="table-wrap table-list">
                    <div class="table-box">
                        <table>
                            <colgroup>
                                <col width="30%">
                                <col width="70%">
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th scope="row">사용자ID(사번)</th>
                                    <td>
                                        <div class="flex-wrap">
                                            <div class="flex110">
                                                <input type="text" v-model.trim="state.dataTable.searchKeyword" @keyup.enter="methods.getUserInfo" placeholder="사용자ID" class="input-text">
                                            </div>
                                            <div class="ml10">
                                                <div class="btn-wrap">
                                                    <button type="button" @click="methods.getUserInfo" class="btn-box">검색</button>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <br>
            <!-- 사용자 목록 데이터 테이블 -->
            <div class="table-wrap">
                <DataTable 
                    class="table-box p-datatable-sm"
                    columnResizeMode="fit"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    :value="state.dataTable.userItems"
                    :paginator="true"
                    :rows="10"
                    :resizableColumns="false"
                    @row-click="eventHandler.dataTable.onRowClick"
                    :rowHover="true">
                    
                    <template #empty>
                        데이터가 없습니다.
                    </template>
                    <Column field="EmplID" header="사용자ID" headerClass="tc" style="text-align:center;" :style="{width: '23%'}"></Column>
                    <Column field="EmplName" header="사용자명" headerClass="tc" :style="{width: '23%'}"></Column>
                    <Column field="DeptCode" header="부서코드" headerClass="tc" :style="{width: '23%'}"></Column>
                    <Column field="Department" header="부서명" headerClass="tc" style="text-align:center;" :style="{width: '41%'}"></Column>
                </DataTable>
            </div>
        </Dialog>
    </div>
</template>
<script>
import { ref, reactive } from '@vue/reactivity'
import { onMounted } from '@vue/runtime-core';
import { complain } from '@/modules/system/PCM_SM0710E.js';
import { userManage } from '@/modules/system/PCM_SM0310E.js';
import { useStore } from 'vuex';
import alertManager from '@/utils/alert-confirm-manager';
export default {
    setup() {
        
        // 스토어
        const store = useStore();

        const state = reactive ({

            // 사용자 정보
            userInfo: new Object(),

            // 데이터 테이블 state
            dataTable: {

                // 코드 목록
                userItems: null,

                // 검색 키워드 바인딩
                searchKeyword: null,

                // 행 데이터 모델
                rowDataModel: new Object(),
            },

            // 사용자 선택 다이얼로그
            dialog: {

                // 다이얼로그 헤더 텍스트
                header: "사용자 선택",

                // 다이얼로그 오픈여부
                isOpen: false,
            },
        });

        // 메서드
        const methods = {

            // 미담당민원지정 조회
            getComplainUserInfo: () => {

                // 스토어 값 변경
                store.commit("setLoadingStatus", true);

                complain.getComplainUserInfo().then(result => {

                    if (!!result) state.userInfo = result[0];
                }).finally(() => {

                    // 스토어 값 변경
                    store.commit("setLoadingStatus", false);
                });
            },

            // 사용자 목록 조회
            getUserInfo: () => {

                userManage.getUserInfo('SY', state.dataTable.searchKeyword).then(result => {

                    state.dataTable.userItems = result.Table.rows;

                }).finally(() => {

                });
            },

            // 미담당민원 사용자 정보 수정
            saveComplainUserInfo: () => {

                alertManager.confirm.question('저장 확인', '저장 하시겠습니까?').then(() => {

                    // 스토어 값 변경
                    store.commit("setLoadingStatus", true);

                    complain.saveComplainUserInfo(state.userInfo).then(result => {

                        if (result.success) {

                            alertManager.alert.success('저장되었습니다.')

                            // 재조회
                            methods.getComplainUserInfo();
                        }
                    }).finally(() => {

                        // 스토어 값 변경
                        store.commit("setLoadingStatus", false);
                    });
                })

                
            }
        };

        // 이벤트 핸들러
        const eventHandler = {

            // 데이터 테이블 관련 이벤트 핸들러
            dataTable: {

                // 행 클릭 이벤트 처리
                onRowClick: event => {

                    state.userInfo = event.data

                    // 팝업 오픈
                    state.dialog.isOpen = false;
                },
            },

            // 사용자 지정 버튼 이벤트 처리
            onUserSetClick: () => {

                // 팝업 오픈
                state.dialog.isOpen = true;
            },
        };

        onMounted(() => {

            // 미담당민원 지정 초기조회
            methods.getComplainUserInfo();

            // 사용자 목록 초기조회
            methods.getUserInfo();
        });

        return {
            state,
            complain,
            userManage,
            methods,
            eventHandler,
        }
    },
}
</script>